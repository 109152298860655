import {MDCTextField} from '@material/textfield';
import {MDCRipple} from '@material/ripple';

const textFields = document.querySelectorAll('.mdc-text-field');

textFields.forEach(function(textField) {
    textField = new MDCTextField(textField);
});


const buttonRipple = new MDCRipple(document.querySelector('.mdc-button'));
